.link-content{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tooltip-content {
    display: flex;
    flex-direction: column;
}
