@import "../../styles/mixins.scss";

:global(.iframe-loaded){
    .box{
        scroll-margin-top: 200px;
    }
}

.box{
    border-radius: 5px;
    padding:16px 24px 36px 24px;
    margin-bottom: 36px;
    scroll-margin-top: 30px;
    @include box-shadow;

    h2{
        margin: 0px 10px 15px 0px;
        display: inline-block;
    }
    .header{
        display:flex;
        justify-content: space-between;
        svg{
            transition: all 0.25s;
            transform: rotate(180deg);
            cursor: pointer;
        }
    }
    .content{
        transition: max-height 0.25s;
        :global(.spinner) {
            text-align: center;
            svg{
                width: 25px;;
            }
        }
    }
    &.toggled{
        .header svg{
            transform: rotate(0deg);
        }
        .content{
            max-height: 0px;
            overflow: hidden;
        }
    }
}