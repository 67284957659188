.modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    aside {
        display: flex;
        justify-content: space-between;
    }
    .datepicker {
        width: 20px;
    }
    .body {
        flex-direction: inherit;
        display: flex;
        gap: 15px;
    }
    .actions {
        display: flex;
        justify-content: end;
        gap: 10px;
    }
}
