.reminder-modal-content {
    p {
        margin-left: 24px;
        &:first-child {
            font-family: Public Sans;
            font-size: 16px;
            font-weight: 600;
            margin-top: 16px;
        }
    }
}
