@import '../../styles/palette.scss';

.textarea-box {
  position: relative;
}

.suggestions {
  position: absolute;
  pointer-events: auto !important;
  max-width: 450px;
  z-index: 10;
}

.suggestions-list {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  list-style-type: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding-left: 0 !important;
  width: 300px;
}

.not-clickable-item {
  padding: 5px 15px;
  border-bottom: 1px solid $bg-blue-gray-light;
  cursor: default;
  background-color: white;
}

.suggestions-item {
  padding: 5px 15px;
  border-bottom: 1px solid $bg-blue-gray-light;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: $bg-blue-gray;
  }
}

.cover-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  white-space: pre-wrap;
  overflow: hidden;
  color: black;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
}
