@import './palette.scss';

@mixin box-shadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px;
}

@mixin vendor-prefix($name, $value) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
      #{$vendor}#{$name}: #{$value};
    }
  }
