@import '../../styles/palette.scss';

.fullscreen{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 9;
    padding-top: 45vh;
    height: 100%;
    background-color: $bg-gray-light;
    opacity: 0.7;
}
