@import "../../styles/palette.scss";

@mixin label{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.modal{
    margin-top: 20px;
    label{
        @include label;
    }
    .summary{
        margin-bottom: 10px;
    }
    .date-selector,.time-selectors{
        svg{
            color: $black;
        }
    }
    .date-selector{
        width: 50%;
        padding-right: 10px;
        :global(div[class^='datepicker_label__']){
            margin-bottom: 0px;
            @include label;
        }
        :global(.MuiInput-root){
            height:50px;
        }
    }
    .time-selectors{
        :global(.MuiInputBase-root){
            height: 50px;
        }
        .timediff{
            text-align: right;
            font-size: 12px;
            color: $dark-gray;
        }
        .starttime,.endtime{
            display: inline-block;
            position:relative;
            width:50%;
            margin-bottom: 5px;
            > div{
                width: 100%;
            }
            .hidden-trigger{
                cursor:pointer;
                position: absolute;
                height:50px;
                margin: 0px;;
                z-index: 1;
            }
        }
        .starttime{
            padding-right: 10px;
        }
        .endtime{
            padding-left: 10px;
        }
    }
    .comment{
        width: 275px;
    }
}
