@import '../../styles/palette.scss';

.content{
    h4 {
        margin-bottom: 10px;
    }

    :global(.search-wrapper) {
        width: 100%;
    }
}
