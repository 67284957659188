@import "../../styles/palette.scss";

.form-control {
    margin-top: 24px;
    &:last-child {
        margin-bottom: 24px;
    }
}

.word-counter {
    display: flex;
    justify-content: end;
    font-weight: 600;
    line-height: 20px;
    color: $dark-gray;
}
