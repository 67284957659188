@import '../../styles/palette.scss';

.container{
    .input {
        width: 100%;
    }
    .actions {
        justify-content: flex-end;
        display: flex;
        gap: 5px;
        align-items: center;
        span {
            font-size: 14px;
            color: $dark-gray;
        }
    }
    p {
        white-space: pre-line;
    }
}
