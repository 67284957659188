.search-bar-box {
  :global {
    #search-bar-combo-box {
      background-image: url('/assets/icons/search.svg');
      background-repeat: no-repeat;
      background-size: 24px;
      margin-left: 8px;
      padding-left: 36px;
      background-position-y: center;
    }
    #search-bar-combo-box-label {
      padding-left: 36px;

      &.Mui-focused {
        padding-left: 0;
      }
    }
  }
}
