@import '../../styles/mixins.scss';
@import '../../styles/palette.scss';

.content{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    p {
        white-space: pre-line;
    }
    svg{
        width:20px;
        &.dot {
            padding: 0 5px;
        }
    }
    .item{
        display: flex;
        .left{
            background: linear-gradient(#94989a, #94989a) no-repeat center/1px 100%;
            .icon-wrap{
                padding:0px 10px;
                background: white;
                border-radius: 50%;
            }
            svg{
                width: 20px;
                height: 20px;
                vertical-align: sub;
            }
        }
        .right{
            margin-bottom: 20px;
            max-width: 100%;
        }
    }
    .comment-box{
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
        margin-top:10px;
        @include box-shadow;
    }
    .comment-type{
        color: $blue;
        font-weight: bold;
        text-transform: uppercase;
        background-color: $bg-blue-gray-light;
        display: block;
        border-radius: 10px;
        width: 80px;
        text-align: center;
        margin-bottom: 10px;
        font-size: 10px;
        padding: 2px 0px;
        svg{
            vertical-align: text-top;
        }
    }
}

.note-content {
    white-space: pre-line;
}
