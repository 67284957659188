@import '../../styles/palette.scss';

.content {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
}

.pointer-box {
    display: inline-block;
    position: relative;
    line-height: 0;
    color: $light-blue;
    cursor: pointer;

    svg {
        fill: $dark-gray;
    }

    &:hover {
        color: darken($light-blue, 10%);
        svg {
            fill: $dark-blue;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
      }
}
