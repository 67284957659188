@import '../../styles/palette.scss';

.content {
    :global(.MuiTableSortLabel-icon) {
        margin: 0 0 0 -2px;
    }

    span {
        text-align: left;
    }

    &:global(.MuiTableSortLabel-root){
        @media (max-width: 1150px) {
            display: flex;
            flex-direction: column;    
        }
    }
}
