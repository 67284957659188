@import '../../styles/palette.scss';

.MuiSwitch-root {
    width: 42px !important;
    height: 26px !important;
    padding: 0 !important;
    margin: 8px !important;
}

.MuiSwitch-switchBase {
    padding: 1px !important;

    &.Mui-checked {
        transform: translateX(16px) !important;
        color: $white !important;

        &+.MuiSwitch-track {
            background-color: $dark-blue !important;
            opacity: 1 !important;
            border: none !important;
        }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
        color: $light-blue !important;
        border: 6px solid $white !important;
    }
}

.MuiSwitch-thumb {
    width: 24px !important;
    height: 24px !important;
}

.MuiSwitch-track {
    border-radius: 13px !important;
    border: 1px solid $bg-blue-gray !important;
    background-color: $bg-gray-light !important;
    opacity: 1 !important;
    transition: background-color 200ms, border 200ms !important;
}
