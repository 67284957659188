@import 'styles/palette.scss';

.content {
  .hr {
    border: 1px solid #CECECE;
    margin: 5px 0;
  }
  .passcode-line {
    display: flex;
    vertical-align: middle;
    line-height: 30px;
  }
  .access-code-loading {
    display: inline-block;
    :global(.spinner) {
      text-align: center;
      svg{
        width: 20px;;
        vertical-align: middle;
        margin-bottom: 5px;
        margin-left: 5px;
      }
    }
  }
  .right-icon-box {
    margin-left: auto;
    margin-right: 0;
    width: 40px;
    text-align: center;
    svg {
      vertical-align: middle;
    }
  }
  .check-icon {
    svg {
      color: $green-success;
    }
  }
  .cross-icon {
    svg {
      color: $dark-red;
      width: 28px;
      height: 28px;
    }
  }
}