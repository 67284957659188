@import '../../styles/palette.scss';

#attachmentsForm {
    padding-bottom: 8px;

    input[type="file"] {
        display: none;
    }

    label {
        font-family: Public Sans;
        font-size: 16px;
        line-height: 24px;
        color: $light-blue;
        &:hover {
            cursor: pointer;
        }
    }
}

.uploadAttachmentsList {
    padding-left: 20px;

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 8px;
    }

    span {
        color: $dark-gray;
        font-family: Public Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}
