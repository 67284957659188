.time{
    margin-top:10px;
    .comment{
        padding-left: 20px;
    }
}

.modal{
    label{
        text-transform: uppercase;
    }
    .summary{
        margin-bottom: 10px;
    }
    .date-selector{
        width: 50%;
        padding-right: 10px;
    }
    .time-selectors{
        .starttime,.endtime{
            display: inline-block;
            width:50%;
            > div{
                width: 100%;
            }
        }
        .starttime{
            padding-right: 10px;
        }
        .endtime{
            padding-left: 10px;
        }
    }
}