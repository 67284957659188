@import '../../styles/palette.scss';

.content {
    &-toggle {
        font-size:12px;
        color: $dark-blue;
        display: flex;
        align-items: center;

        &:global(.disabled) {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .toggle {
            display: inline-block;
            background-color: $bg-blue-gray-light;    
            border-radius: 50px;
            height:24px;
            .left, .right {
                cursor: pointer;
                text-align: center;
                display: inline-block;
                width: 72px;
                height:24px;
                border-radius: 50px;;
                vertical-align: top;
                svg{
                    height: 20px;
                    width:20px;
                    padding-top: 4px;
                }
            }
            .active {
                background-color: $bg-blue-gray;
            }
        }
        .label {
            font-weight: bold;
            text-transform: uppercase;
            display: inline-block;
            margin: 0px 10px;
            text-align: center;
            min-width: 100px;
        }
    }
    
}
