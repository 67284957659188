@import '../../styles/palette.scss';

.content{
    text-decoration: none;
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    background: linear-gradient(90deg, rgba(230,164,25,1) 25%, rgba(250,228,180,1) 100%);
    padding: 15px 30px;
    margin-bottom: 20px;
    align-items: center;
    color: $white;
    border-radius: 12px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);

    div {
        display: flex;
    }

    > div {
        column-gap: 20px;
    }

    .link-btn {
        border-radius: 8px;
        padding: 5px 20px;
        background-color: $white;
        border: thin solid;
        font-size: 18px;
        border-radius: 8px;
        border-width: 2px;
        cursor: pointer;
    }

    .close-btn {
        display: flex;
        align-items: center;
        border: none;
        cursor: pointer;
    }
    .left-side {
        svg {
            height: 50px;
            width:  50px;
        }
    }

    .right-side {
        button {
            font-weight: bold;
            color: $dark-yellow;
            svg {
                height: 25px;
                width: 25px;
                color: $dark-yellow;
            }
        }
    }
}
