@import "../../styles/palette.scss";

.content{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .item {
        width: 22%;
    }
    .datepicker {
        padding: 4px;
    }
    > div {
        > div {
            > div:last-child {
                background-color: $white;
                border-radius: 6px;
            }
        }
    }
}
