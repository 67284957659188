.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 10px 0;

    > div {
        textarea {
            min-height: 300px;
        }
    }
}
