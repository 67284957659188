@import '../../styles/palette.scss';

.subheader{
    display:flex;
    justify-content: space-around;
    margin-top: 30px;
}

.spinner{
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 200px;
}

.container{
    padding:15px 25px;
}

.content{
    display:flex;
    justify-content: space-between;
    margin-top: 40px;
    .content-left{
        width: 60%;
    }
    .content-right{
        width: 32%;
    }
}
