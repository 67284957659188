@import '../../styles/palette.scss';

.content{
    h4 {
        margin-bottom: 10px;
    }

    :global(.MuiOutlinedInput-root) {
        height: 40px;
    }

    :global(.MuiFormControl-root) {
        width: 100%;
    }
}
