@import '../../styles/palette.scss';

.content{
    color: $dark-blue;
    .accept-button{
        display:block;
        margin-left: auto;
        margin-top: 10px;;
    }
    .left-container {
        p {
            margin: 15px 0;
            font-weight: 600;
            min-height: 50px;
        }
        .button-container {
            display: flex;
            gap: 5px
        }
    }
    .right-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;
        text-align: left;
        justify-content: end;
        font-size: 21px;
        h3 {
            font-weight: normal;
        }
    }
}
