.spinner {
  text-align: center;
  svg{
    width:20px;
    vertical-align: middle;
    &.dot {
      padding: 0 5px;
    }
  }
}
