@import '../../styles/palette.scss';

.header{
    background: $dark-blue;
    height:56px;
    display:flex;
    align-items: center;
    padding:0px 24px;
    color: white;
    svg {
        width:120px;
        height:27px;
    }
}