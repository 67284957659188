$multi-search-width: 420px;

.content{
    display: flex;
    flex-direction: column;
    gap: 10px;

    :global(.searchWrapper) {
        min-width: $multi-search-width;
    }
    :global(.optionListContainer) {
        width: $multi-search-width;
    }
    &.hide-input {
        input {
            display: none;
        }
    }
}
