@import '../../styles/palette.scss';

.container{
    display: flex;
    align-items: center;
    min-height: 38px;

    div {
        display: flex;
        align-items: center;

        &.visibility-toggle{
            border: 1px solid $bg-blue-gray-light;
            border-radius: 5px;
            padding: 5px;
            margin-right: 5px;
        }
    }

    .actions {
        justify-content: flex-end;
        display: flex;
        gap: 5px;
    }
    .visibility-label{
        display: inline-block;
        background-color: $dark-yellow;
        text-transform: uppercase;    
        border-radius: 50px;
        font-weight: 800;
        font-size: 12px;
        padding: 5px 15px;
        letter-spacing: 1.5px;
    }
}
