:global [class^='modal_modalWindow__'] > div > div {
    scroll-margin-top: 100px;
}

.actions{
    display: flex;
    justify-content: end;
}
.padding-thin{
    padding: 7px !important;
}
.split-flex{
    display: flex;
    margin-bottom: 0 !important;
    > div{
        width:50%;
        &:first-child{
            padding-right: 10px;
        }
        &:last-child{
            padding-left: 10px;
        }
    }
}

.start-time{
    display: inline-block;
    position:relative;
    margin-bottom: 5px;
    .hidden-trigger{
        cursor:pointer;
        position: absolute;
        height:35px;
        width: 100%;
        margin: 0 !important;
        z-index: 1;
    }
}