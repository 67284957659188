@import '../../styles/palette.scss';

.content{
    aside {
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
        margin-bottom: 20px;
        button {
            background-color: $white;
        }
    }
}
