@import "../../styles/base.scss";


.modal-title {
    font-family: $main-font-family;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.5px;
}

.severity-label {
    p {
        font-family: $main-font-family;
        font-size: 12px;
        font-weight: 800;
        line-height: 16px;
        letter-spacing: 2px;
        margin-bottom: 9px;
    }
}
