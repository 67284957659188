.button {
    position: relative;

    svg {
        width: 28px;
    }
    :global(.spinner) {
        position: absolute;
        top: 2px;
        left: calc(50% - 14px);
    }
    &:global(.small) {
        font-size: 12px;
        line-height: 24px;
        padding: 0px 20px;
        height: 31px;

        svg {
            width: 23px;
        }
    }
}
