@import "../../styles/palette.scss";

@mixin label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.modal{
  margin-top: 20px;
  label{
    @include label;
  }
  .reassign-btn {
    width: 120px;
    svg{
      width:20px;
      &.dot {
        padding: 0 5px;
      }
    }
  }
  .cancel-btn{
    margin: 0 5px 0 auto;
  }
  .assignment-actions-box {
    font-size: 12px;
    margin-top: 8px;
    display: flex;
    justify-content: end;
  }
  :global {
      #assignable-users-combo-box {
        background-image: url('/assets/icons/search.svg');
        background-repeat: no-repeat;
        background-size: 24px;
        margin-left: 8px;
        padding-left: 36px;
        background-position-y: center;
      }
      #assignable-users-combo-box-label {
        padding-left: 36px;

        &.Mui-focused {
          padding-left: 0;
        }
      }
  }
}
