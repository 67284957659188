@import '../../styles/palette.scss';

.content{
    margin-top: 20px;
    
    :global(.MuiTableHead-root) {
        background-color: $bg-blue-gray-light;
    }

    :global(.MuiTableCell-head) {
        font-weight: 700;
    }

    thead {
        border: 1px solid $bg-gray;
        border-width: 1px 0 1px 0;
    }

    tbody {
        tr {
            background-color: $bg-gray-light;
            margin: 5px 0;
            min-height: 75px;
            cursor: pointer;

            &:first-child {
                margin-top: 10px;
            }

            &:hover {
                background-color: $bg-blue-gray-light;
            }
        }
    }

    tr {
        width: 100%;
        display: inline-flex;
    }

    :global(.MuiTableCell-root) {
        display: inline-flex;
        align-items: center;
    }

    :global(.MuiTableCell-alignCenter) {
        justify-content: center;
    }

    :global(.MuiTablePagination-select) {
        border: thin solid $bg-blue-gray-light;
        border-radius: 5px;
        min-width: 30px;
    }
}
