@import '../../styles/palette.scss';

.warning-box-icon{
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  svg{
    color: $light-red;
  }
}
