.editFollowerEditContainer {
    h4 {
        font-size: 12px;
        margin-bottom: 8px;
    }
    :global {
        #combo-box-demo {
            background-image: url('/assets/icons/search.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            margin-left: 8px;
            padding-left: 36px;
            background-position-y: center;
        }
        #combo-box-demo-label {
            padding-left: 36px;
        
            &.Mui-focused {
                padding-left: 0;
            }
        }
        .editFollowerEditActions {
            margin-top: 8px;
            display: flex;
            justify-content: end;
        }
    }
}

.followerList {
    h4 {
        font-size: 12px;
        padding-top: 16px;
    }
    ul {
        list-style-type: none;
        padding: 0;
        li:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}

.remove-spinner,
.self-spinner {
    margin-left: 4px;
    display: inline-block;
    svg {
        vertical-align: middle;
        width: 20px !important;
    }
}
